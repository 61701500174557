<template>
  <div class="home">
    <translation-home></translation-home>
  </div>
</template>

<script>
import translationHome from "@/components/TranslationHome.vue";

export default {
  name: 'Home',
  components: {translationHome},
}
</script>
